import React from 'react';
import {useSelector} from 'react-redux';
import UserInfo from '../user-info/UserInfo';
import { data } from '../../constants';
import './topnav.scss';

const TopNav = () => {
    const openSidebar = () => {
        document.body.classList.add('sidebar-open')
    }

    const {
      details
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

    return (
        <>
            <div className='topnav'>
                <div>
                    <div className="logo_black"><span className="logo_red">One</span> Attendance</div>
                    <div className=""><span className="powered_by">powered by</span> <span className="fw-bold smmc_text">SMM:C</span></div>
                </div>
                <div className="d-flex">
                    <UserInfo user={(details || data.user)} />
                    <div className="sidebar-toggle" onClick={openSidebar}>
                        <i className='bx bx-menu-alt-right'></i>
                    </div>
                </div>

            </div>
            <hr />
        </>
    )
}

export default TopNav
