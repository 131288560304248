import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DatePicker } from 'rsuite';
import {addZeroBefore} from '../../util/DateTimeConverter';
import './timepicker.scss';

const TimePickerView = (props) => {
	const {value = null, isRequired = false, isError=false, isInvalid=false, setUpdateTime } = props;

	const [time, setTime] = useState(null);

	const handleTime = (date) => {
		const hrs = addZeroBefore(date.getHours());
		const mins = addZeroBefore(date.getMinutes());
		const time = `${hrs}:${mins}`;
		setUpdateTime(time);
	}

	useEffect(() => {
		if(value && value !== "" && value !== "--:-- AM" && value !== "--:-- PM") {
			const splitData = value.toString().split(":");

			if(!splitData[1]) {
				splitData[1] = "00";
			}
			const d = new Date(`July 21, 2023 ${splitData[0]}:${splitData[1]}`);
			setTime(d);
		}
	}, [value]);

	return (
		<div className={`time-picker ${isError ? 'invalid-timepicker': ''}`}>
			<DatePicker 
				required={isRequired} 
				value={time}
				aria-invalid={isInvalid} 
				format="HH:mm" ranges={[]} 
				style={{ width: '100%' }} 
				onChange={handleTime} 
				cleanable={false} 
			/>
		</div>
	)
}

export default TimePickerView;