import { Business } from "@mui/icons-material";
import { PersonAdd, AccessAlarm } from "@mui/icons-material";

const sidebarNav = [
    {
        link: '/',
        section: 'home',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Home'
    },
    {
        link: '/report',
        section: 'report',
        icon: <i className='bx bx-receipt' ></i>,
        text: 'report'
    },
    {
        link: '/employes',
        section: 'employes',
        icon: <i className='bx bx-user'></i>,
        text: 'Employes'
    },
    {
        link: '/offices',
        section: 'offices',
        icon: <Business />,
        text: 'Offices'
    },
    {
        link: '/roster',
        section: 'roster',
        icon: <AccessAlarm />,
        text: 'Roster'
    },
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bxs-cog'></i>,
        text: 'Settings'
    }
]

export default sidebarNav