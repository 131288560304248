import {useState, useEffect} from 'react';
import Input from '../../components/Input';
import SelectView from '../../components/Select/SelectView';
import MapView from './MapView';

import { 
    AttendanceType,
    isPhoneNumberValid
} from '../../util/utils';

const OfficeLocationDetails = (props) => {
    const {office = {}, setOffice, isEdit=false, handleClose, setStepper} = props;

    const initErrorState = {
        officeAddressError: false,
        officeCityError: false,
        officeStateError: false,
        officeCountryError: false,
        officePostalCodeError: false
    };

    const [errors, setErrors] = useState(initErrorState);
    const [isFormValid, setFormValid] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValidForm = validate();

        if(isValidForm) {
            setStepper(4);
        }
    }

    useEffect(() => {
        if (!isFormValid) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setFormValid(true);
        }
    }, [isFormValid]);

    const validate = () => {
        let isInvalidOfcAddress = false;
        let isInvalidOfcCity = false;
        let isInvalidOfcState = false;
        let isInvalidOfcCountry = false;
        let isInvalidOfcPostalCode = false;

        let isValidForm = true;

        if(address === "") {
            isInvalidOfcAddress = true;
            isValidForm = false;
        }

        /*if(city === "") {
            isInvalidOfcCity = true;
            isValidForm = false;
        }*/

        if(state === "") {
            isInvalidOfcState = true;
            isValidForm = false;
        }

        if(country === "") {
            isInvalidOfcCountry = true;
            isValidForm = false;
        }

        if(postalCode === "") {
            isInvalidOfcPostalCode = true;
            isValidForm = false;
        }

        setErrors({...errors,
            officeAddressError: isInvalidOfcAddress,
            officeCityError: isInvalidOfcCity,
            officeStateError: isInvalidOfcState,
            officeCountryError: isInvalidOfcCountry,
            officePostalCodeError: isInvalidOfcPostalCode
        });

        setFormValid(isValidForm);
        return isValidForm;

    }

    const onInputChange = (e) => {
        setOffice({...office, [e.target.name] : e.target.value});
    }

    const updateOfcConfigData = (e) => {
        setOffice({...office, office_geofencing_conf: {...office_geofencing_conf, [e.target.name]: e.target.value}});
    }

    useEffect(() => {
        if (!isFormValid) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setFormValid(true);
        }
    }, [isFormValid]);

    const {
        office_geofencing_conf,
        office_name
    } = office;

    const {
        lat = "",
        lng = "",
        radius = "",
        address = "",
        city = "",
        state = "",
        country = "",
        postalCode = ""
    } = office_geofencing_conf || {};

    const {
        officeAddressError = false,
        officeCityError = false,
        officeStateError = false,
        officeCountryError = false,
        officePostalCodeError = false
    } = errors;

	return (
		<form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate className="p-lg-5 p-md-5 p-2">
            <h6>Configure Location</h6>
            <p className="pt-2">Please enter the office location details</p>
            <p>You may skip this step and configure it at a later time</p>

            <div className="py-4">
                <MapView lat={lat} lng={lng} />
            </div>

            <div className="row">
                <div className="col-lg-12 py-2">
                    <Input
                        label="Address"
                        name="address"
                        value={address}
                        isRequired={true}
                        isInvalid={officeAddressError}
                        isError={officeAddressError}
                        onInputChange={(e) => updateOfcConfigData(e)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 py-2">
                    <Input
                        label="City"
                        name="city"
                        value={city}
                        isRequired={false}
                        isInvalid={officeCityError}
                        isError={officeCityError}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />    
                </div>

                 <div className="col-lg-6 py-2">
                    <Input
                        label="State"
                        name="state"
                        value={state}
                        isRequired={true}
                        isInvalid={officeStateError}
                        isError={officeStateError}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 py-2">
                    <Input
                        label="Country"
                        name="country"
                        value={country}
                        isRequired={true}
                        isInvalid={officeCountryError}
                        isError={officeCountryError}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />    
                </div>

                <div className="col-lg-6 py-2">
                    <Input
                        label="Zipcode"
                        name="postalCode"
                        value={postalCode}
                        isRequired={true}
                        isInvalid={officePostalCodeError}
                        isError={officePostalCodeError}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />    
                </div>
            </div>

            <div className="d-flex justify-content-end pt-3 px-2">
                <button type="button" className="btn btn-outline-danger mt-3 mr-3 px-5" onClick={() => setStepper(2)}>Back</button>
                <button type="submit" className="btn btn-danger ms-3 mt-3 px-5">Next</button>
            </div>
        </form>
	)
}

export default OfficeLocationDetails;