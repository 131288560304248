import React, {useState} from 'react';
import Table from '../../components/table/Table';
import EditShift from "./EditShift";
import ConfirmPayroll from './ConfirmPayroll';
import Tooltip from '@mui/material/Tooltip';
import { PersonAdd, AccessAlarm } from "@mui/icons-material";
import {InititalShiftData} from '../../util/DefaultInitialValues';

const ShiftConfigure = ({data}) => {

  const [details, setDetails] = useState(null);
  const [isShow, setShow] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);

	const columns = [
    {
      name: "shift_id",
      label: 'ID',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "shift_name",
      label: 'Name',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "shift_in_time",
      label: 'In Time',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "shift_out_time",
      label: 'Out Time',
      options: {
       filter: true,
       sort: true
      }
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
     
          const key_val = tableMeta.rowData[0];
          return (
            <div className="d-flex align-items-center w-100">
              <button className="rounded-btn fs-6 mx-2" title='Edit' onClick={() => handleEdit(key_val)} >
                <i className='bx bx-edit'></i>
                <span className="px-2">Edit</span>
              </button>

              <button className="rounded-btn fs-6 mx-2" title='Delete' onClick={() => handleDelete(key_val)} >
                <i className='bx bx-message-alt-x' ></i>
                <span className="px-2">Delete</span>
              </button>
            </div>
          );
        }
      },
    }
  ];

  const addPayroll = () => {
    setDetails(InititalShiftData);
    setShow(true);
  }

  const AddButton = () => (
    <Tooltip disableFocusListener title='Add Shift'>
      <button className="MuiButtonBase-root MuiIconButton-root ax-button" onClick={() => addPayroll()} >
         <AccessAlarm />
      </button>
    </Tooltip>
  );

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    print: true,
    rowsPerPage: 100,
    customToolbar: AddButton,
    sortOrder: {
      name: 'shift_name',
      direction: 'asc'
    }
  };

  const handleEdit = (val) => {
    const filterItem = data && data.filter((item, index) => item.shift_id === val);
    if(filterItem && filterItem.length > 0) {
      setDetails(filterItem[0]);
      setShow(true);
     
    }
  }

  const handleDelete = (val) => {
    const filterItem = data && data.filter((item, index) => item.shift_id === val);
    if(filterItem && filterItem.length > 0) {
      setDetails(filterItem[0]);
      setShowConfirm(true);
    }
  }

	return (
		<div className="my-5">
    	<div className="container-fluid">
				<Table headers={columns} rowData={data} options={options} clsName = "shift" />
        {isShow && <EditShift isOpen={isShow} details={details} onHandleCloseDialog = {() => setShow(false)} />}
        {isShowConfirm && <ConfirmPayroll pageName="Shift" details={details} isOpen={isShowConfirm} onHandleCloseDialog = {() => setShowConfirm(false)} /> }
			</div>
		</div>
	)
}

export default ShiftConfigure;