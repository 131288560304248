import React, { useState, useEffect } from 'react';
import Input from '../../components/Input';
import Dialog from '@mui/material/Dialog';
import TimePickerView from '../../components/TimePicker/TimePickerView';
import {isTextValid} from '../../util/DateTimeConverter';

const EditBulkShift = (props) => {
	const { isOpen = false , onUpdateShift, onHandleCloseDialog} = props;

    const [open, setOpen] = useState(isOpen);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    const [shiftDetails, setShiftDetails] = useState({shift_in_time : "", shift_out_time : ""});

    const initErrorState = {
        inTimeError: false,
        outTimeError: false
    };
    
    const [errors, setErrors] = useState(initErrorState);

    const {
        shift_in_time = "",
        shift_out_time = "",

    } = shiftDetails || {};

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = (event, reason) => {
       // if (reason && reason == "backdropClick") return;
        setOpen(false);
        onHandleCloseDialog();
    };

    const handleSubmit = (e) => {
    	 e.preventDefault();
     
        const isFormValid = validate();
        if (isFormValid) {
            onUpdateShift(shiftDetails);
        }
    }

    const validate = (e) => {
        let isFormValid = true;
       
        let isinValidInTime = false;
        let isinValidOutTime = false;

        if (!isTextValid(shift_in_time)) {
            isFormValid = false;
            isinValidInTime = true;
        }

        if (!isTextValid(shift_out_time)) {
            isFormValid = false;
            isinValidOutTime = true;
        }
        
        setErrors({...errors,
            inTimeError: isinValidInTime,
            outTimeError: isinValidOutTime
        });
        return isFormValid;
    }

    const updateShiftDetails = (name, val) => {
    	setShiftDetails({...shiftDetails, [name]: val});
    }

	const title ='Update Shift';

    return (
        <Dialog 
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isOpen}
            onClose={handleClose}
            aria-label=""
            className="md"
            //onBackdropClick="false"
        >
       
            <div className="container p-lg-5 p-3">
                
                <form  onSubmit={e => handleSubmit(e)} autoComplete="off" noValidate>
                    <h2 className="form-heading">{title}</h2>
              
                    <div className="row">
                        <div className="col-lg-6 py-4">
                            <p className="field pb-2">Shift In Time *</p>
                            <TimePickerView 
                                label="In Time"
                                name="shift_in_time"
                                value={shift_in_time} 
                                isRequired={true}
                                isInvalid={errors.inTimeError}
                                isError={errors.inTimeError}
                                setUpdateTime={(value) => updateShiftDetails("shift_in_time", value)} 
                            />  
                        </div>

                        <div className="col-lg-6 py-4">
                            <p className="field pb-2">Shift Out Time *</p>
                            <TimePickerView 
                                label="Out Time"
                                name="shift_out_time"
                                value={shift_out_time} 
                                isRequired={true}
                                isInvalid={errors.outTimeError}
                                isError={errors.outTimeError}
                                setUpdateTime={(value) => updateShiftDetails("shift_out_time", value)} 
                            />   
                        </div>
                    </div>

                    <div className="d-flex pt-3">
                        <button type="submit" className="btn btn-danger mt-3 mr-3">Update</button>
                        <button type="button" className="btn btn-outline-danger ms-3 mt-3" onClick={handleClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </Dialog>
	)
}

export default EditBulkShift;