import React, {useEffect, useState, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {addZeroBefore, isPhoneNumberValid} from '../../util/DateTimeConverter';

import {saveAdmin} from '../../actions/admin.actions.js';
import {saveFilters} from '../../actions/filters.actions.js';
import Loading from '../../components/loading/Loading';
import {useDispatch} from 'react-redux';
import {InitialFilters} from '../../util/DefaultInitialValues';

import {getAllOfficesByLegalEntity} from '../../actions/offices.actions.js';
import {getConfigurationByLE, saveLegalEntity} from '../../actions/filters.actions.js';
import {baseURL} from '../../actions/Constants';
import {generateLEList} from '../../util/utils';
import {PCC_OneAttendance_version} from '../../actions/Constants';

import wave from '../../assets/wave.png';
import emp_icon from '../../assets/images/emp.png';
import pcc_logo from '../../assets/ic_logo_oneattendance5.png';

import './login.scss';

const Login = () => {
	const navigate = useNavigate();
  const dispatch = useDispatch();
	const [otp, setOtp] = useState(new Array(4).fill(''));
	const [isShowOTPField, setIsShowOTPField] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [inValidOTP, setInvalidOTP] = useState(false);
	const [isInvalidPhone, setInvalidPhone] = useState(false);
	const [timer, setTimer] = useState(59);
	const [isShowTimer, setShowTimer] = useState(false);
	const id = useRef(null);
  
  const [emp, setEmployee] = useState(null);
  const [filters, setFilters] = useState(InitialFilters);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSubmit, setSubmitState] = useState(false);
  const [isInvalidUser, setIsInvalidUser] =  useState(false);
  const [isUserExist, setUserExist] = useState(true);

  const [fullEmpDetails, SetFullEmpDetails] = useState(null);
  
  const saveAdminAction = saveAdmin;
  const saveFilterDataAction = saveFilters;
  const saveLegalEntityAction = saveLegalEntity;

  useEffect(() => {
    return () => {
      clear();
    }
  }, []);

  useEffect(() => {
    if (emp && isSubmit) {
      if(emp.emp_role.toLowerCase().includes('admin') && emp.emp_phone_no === phoneNumber) {
        setIsShowOTPField(true);
        startTimer();
        dispatch(getAllOfficesByLegalEntity(emp.legal_entity_id));
        dispatch(getConfigurationByLE(emp.legal_entity_id));
        getOTP();
        setIsInvalidUser(false);
      } else {
        setIsInvalidUser(true);
        setSubmitState(false);
      }
      setSubmitState(false);
    }
  }, [emp]);

  const getEmployeeByPhone = async (phone) => {
    const URL=`${baseURL}/getEmployeeByPhoneNumber`;

    const obj = {};
    obj.emp_phone_no = phone;

    setLoading(true);

    return await fetch(URL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
        headers: {
          'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
    .then((res) => {
      //res = DummyData; //Amit
      if(res && res.data.length === 0) {
          setUserExist(false);
      } else {
        isAdmin(res.data);
        SetFullEmpDetails(res.data);
      }
      setLoading(false);
      setError(false);
    })
    .catch((error) => {
        console.log('error getEmployeeByPhone', error);
        setLoading(false);
        setError(true);
        setInvalidPhone(true);
    });
  };

  const isAdmin = (userList) => {
    const list = userList;

    const adminUser = list.filter((item, index) => item.emp_role.toLowerCase().includes('admin') && item.emp_phone_no === phoneNumber);
   
    if(adminUser.length > 0) {
      setEmployee(adminUser[0]);
    } else {
      setEmployee(list[0]);
    }
  }

  const handleSubmit = (e) => {
  	e.preventDefault();
    const phoneNo = phoneNumber;
    let isAllFilled = true;
    setInvalidPhone(false);
    setUserExist(true);
    setInvalidOTP(false);

  	if(!isShowOTPField) {
  		// Validate Phone number count
  		// If valid, request for OTP
      clear();
      setEmployee(null);
  		const isNumberValid = isPhoneNumberValid(phoneNumber);
  		
      if(isNumberValid) {
  			//getOTP();
  			//startTimer();
        setSubmitState(true);
        getEmployeeByPhone(phoneNo);
  		} else {
  			setInvalidPhone(true);
  		}
  	} else {
  		//Check OTP is valid or not. On success
  		const emptyOTPArray = otp.filter((d, idx) => (d ===''));
  		if(emptyOTPArray.length !== 0) {
  			setInvalidOTP(true);
  		} else {
  			verifyOTP(otp);
  			//navigate('/'); //remove this in actual
  		}
  	}
  }

  const getOTP = () => {
    const URL = `${baseURL}/getOTP?phone_number=${phoneNumber}`;
    fetch(URL)
    .then(res => res.json())
    .then(res => {

    })
    .catch(error => {
      console.log('error', error);
    })
  };

  const verifyOTP = (otp) => {
    const receivedOTP = otp.join("");

    const obj = {};
    obj.emp_phone_no = phoneNumber;
    obj.otp = receivedOTP;
    const URL = `${baseURL}/verifyOTP`;

    fetch(URL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
        headers: {
          'Content-Type': 'application/json',
        }
    })
    .then(res=> res.json())
    .then(res => {
        const {status = "", data = {}} = res;
        const {message = ""} = data || {};
        //if (status === "SUCCESS") {
        if (status === "SUCCESS" && message === "OTP verified success") {
          const LEList = generateLEList(fullEmpDetails);
          dispatch(saveLegalEntityAction(LEList));
          filters.emp_legal_entity_id = "";
          dispatch(saveAdminAction(emp));
          dispatch(saveFilterDataAction(filters));
          localStorage.setItem("PCC_OneAttendance_version", PCC_OneAttendance_version);
          navigate('/configure-device');
        } else {
          setInvalidOTP(true);
        }
    })
    .catch(error => {
      console.log('error', error);
    })
  }

  const handleKeydown = (e, index) => {
  	const keyCode = e.which;
  	
  	switch(keyCode) {
  		case 37:
  		case 38:
  			if(index <= 3 && index> 0) {
		    	const ele = document.getElementById(`digit-${index}`);
		    	ele.focus();
    		}
    	break;
    	case 39:
  		case 40:
  			if(index <= 2) {
		    	const ele = document.getElementById(`digit-${index+2}`);
		    	ele.focus();
    		}
    	break;
    	default: break;
  	}
  }

  const handlePhoneNumber = (e) => {
  	setPhoneNumber(e.target.value);
  }
 
  const handleChange = (e, index) => {
    let element = e.target;
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d,idx) => (idx === index) ? element.value: d)]);
    element = e.target;
    if(element.value !== "" && index < 3) {
    	const ele = document.getElementById(`digit-${index+2}`);
    	ele.focus();
    }
  }

	const startTimer = () => {
    setShowTimer(true);
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  }

	const clear = () => {
      if(id) window.clearInterval(id.current);
	};

	useEffect(() => {
    if (timer === 0 || !isShowOTPField) {
      clear();
      setTimer(59);
      setShowTimer(false);
    }
    else {
        setTimer(timer);
    }
	}, [timer]);

	const handleChangeView = () => {
		setIsShowOTPField(false);
		handleResendOtp();
	}

	const handleResendOtp = () => {
		setOtp(new Array(4).fill(''));
	}

  const titleVal = "Login - OneAttendance";
  const hrefVal = window.location.href;

  return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div className="login">
        <div className="wave">
          <img src={wave} alt = "" />
        </div>
        
        <div className="pcc_logo_container">
          <img src={pcc_logo} alt = "" className="pcc_logo" />
          <div className="ps-3 powered_by_text"><span className="powered_by">powered by</span> <span className="fw-bold smmc_text">SMM:C</span></div>
        </div>

        <div className="footer-text">© SMM:C · Contact · Privacy & terms</div>
        <div className="login_container">
          <div className="login-content flex-column">
            <div className="d-flex align-items-center my-3">
                <span className="pe-2 logo_black inter_regular">Welcome to </span>
                <div className="logo_black"><span className="logo_red">One</span> Attendance</div> 
            </div>
            
            <div className="mt-5">
              <div className="d-flex align-items-center">
                <div> 
                  <img src={emp_icon} className="pe-3" alt = "" /> 
                </div>

                <div>
                  <div className="dialog_h2_heading">Sign In</div>
                  <div className="mt-3">
                    <div className="dialogSubText">Please use your registered</div>
                    <div className="dialogSubText">phone number to sign in using OTP </div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={e=>handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>

    					{isShowOTPField && <p>We have sent One time password to your register phone number. <button className="ax-button bold italic red" onClick={handleChangeView}>Change</button></p>}
            
              {!isShowOTPField && <div>
           		 
           		  <div className="py-3">
         		   		<input type="text" disabled={isShowOTPField} placeholder="Phone Number" onChange={e => handlePhoneNumber(e)} className="w-100 p-3 phone" />
           		  </div>
       		    </div>}

    	        {isInvalidPhone && <p className="red">! Invalid Phone Number</p>}
              {(!isUserExist || isInvalidUser) && <p>
                  Phone number not registered.Contact your IT team
                  or contact <a href="mailto:hello@theminded.club">admin@oneattendance.com</a>
                </p>
              }

        	    {isShowOTPField &&
  			        <div className="flex otp-container my-3">
                  {otp.map((val, index) => {
                    return (
                    <input
                       className="otp-field"
                       id={`digit-${index+1}`}
                       type="text"
                       name={`digit-${index+1}`}
                       maxLength="1"
                       key={index}
                       value={val}
                       focus={index === 0}
                       onKeyDown={e => handleKeydown(e, index)} 
                       onChange={e => handleChange(e, index)}
                    />
                  );
                })}
              </div>
        		}

  					{isShowOTPField && <div className="mt-3">
  						Not received OTP?
  						{isShowTimer && <span>&nbsp; Resend OTP in 00 : {addZeroBefore(timer)}</span>}
  						{!isShowTimer && <button type="button" className="ax-button bold italic" onClick={handleResendOtp}>&nbsp; Resend</button>}
  					</div>}
                
  			    {inValidOTP && <p className="red mt-3">! Invalid OTP</p>}

  	        {<input type="submit" className="1login_btn btn btn-outline-danger border-1 rounded-2 my-3" value={`${isShowOTPField ? 'Login': 'Get OTP'}`} />}
  	      </form>
  	    </div>
      </div>
    </div>
    {isLoading && <Loading />}
  </>
	)
};

export default Login;