import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Input from '../../components/Input';
import SelectView from '../../components/Select/SelectView';
import {WeeklyOffDays, isEmailValid} from '../../util/utils';

import { 
    AttendanceType,
    isPhoneNumberValid
} from '../../util/utils';

const OfficeDetails = (props) => {
    const {office = {}, setOffice, isEdit=false, handleClose, setStepper} = props;

    const {
      details:loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

    const initErrorState = {
        officeIDError: false,
        officeNameError: false,
        officeEmailError: false,
        officeAddressError: false,
        officePhoneError: false,
        officeLEError: false,
        officeOffDayError: false,
        officeAttendanceTypeError: false,
        officeDescError: false
    };

    const [errors, setErrors] = useState(initErrorState);
    const [isFormValid, setFormValid] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValidForm = validate();

        if(isValidForm) {
            setStepper(2);
        }
    }

    useEffect(() => {
        if (!isFormValid) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setFormValid(true);
        }
    }, [isFormValid]);

    const validate = () => {
        let isInvalidOfcID = false;
        let isInvalidOfcName = false;
        let isInvalidOfcEmail = false;
        let isInvalidOfcAddress = false;
        let isInvalidOfcPhone = false;
        let isInvalidOfcLE = false;
        let isInvalidOfcOffDay = false;
        let isInvalidOfcAtnType = false;
        let isInvalidOfcDescription = false;

        let isValidForm = true;

        if(office_id === "") {
            isInvalidOfcID = true;
            isValidForm = false;
        }

        if(office_name === "") {
            isInvalidOfcName = true;
            isValidForm = false;
        }

        if(office_email === "" || !isEmailValid(office_email)) {
            isInvalidOfcEmail = true;
            isValidForm = false;
        }

        if(office_address === "") {
            isInvalidOfcAddress = true;
            isValidForm = false;
        }

        if(office_phone === "") {
            isInvalidOfcPhone = true;
            isValidForm = false;
        }

        if(office_configuration.office_off_day === "") {
            isInvalidOfcOffDay = true;
            isValidForm = false;
        }

        if(office_configuration.attendance_method === "") {
            isInvalidOfcAtnType = true;
            isValidForm = false;
        }

        if(office_description === "") {
            isInvalidOfcDescription = true;
            isValidForm = false;
        }

        setErrors({...errors,
            officeIDError: isInvalidOfcID,
            officeNameError: isInvalidOfcName,
            officeEmailError : isInvalidOfcEmail,
            officeAddressError: isInvalidOfcAddress,
            officePhoneError: isInvalidOfcPhone,
            officeLEError: isInvalidOfcLE,
            officeOffDayError: isInvalidOfcOffDay,
            officeAttendanceTypeError: isInvalidOfcAtnType,
            officeDescError: isInvalidOfcDescription
        });

        setFormValid(isValidForm);
        return isValidForm;

    }

    const onInputChange = (e) => {
        setOffice({...office, [e.target.name] : e.target.value});
    }

    const updateOfcConfigData = (e) => {
        setOffice({...office, office_configuration: {...office_configuration, [e.target.name]: e.target.value}});
    }

    useEffect(() => {
        setOffice({...office, legal_entity_id : loggedAdminDetails && loggedAdminDetails.legal_entity_id});
    }, []);

    const {
        office_id = "",
        office_name = "",
        office_phone= "",
        legal_entity_id = "",
        office_address = "",
        office_configuration = {
            office_off_day: "",
            attendance_method: ""
        },
        office_email = "",
        office_description = ""
    } = office || {};

	return (
		<form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate className="p-lg-5 p-md-5 p-2">
            <h6>Office Details</h6>
            <div className="row">
                <div className="col-lg-6 py-2">
                    <Input
                        label="ID"
                        name="office_id"
                        value={office_id}
                        errorFieldName="Office ID"
                        isInvalid={errors.officeIDError}
                        isError={errors.officeIDError}
                        isRequired={true}
                        onInputChange={(e) => onInputChange(e)}
                        isDisabled={isEdit ? true : false}
                    />
                </div>

                <div className="col-lg-6 py-2">
                    <Input
                        label="Name"
                        name="office_name"
                        value={office_name}
                        errorFieldName="Name"
                        isInvalid={errors.officeNameError}
                        isError={errors.officeNameError}
                        isRequired={true}
                        onInputChange={(e) => onInputChange(e)} 
                    />    
                </div>
            </div>

           <div className="row">
                <div className="col-lg-6 py-2">
                    <Input
                        label="Email"
                        name="office_email"
                        value={office_email}
                        isRequired={true}
                        isInvalid={errors.officeEmailError}
                        isError={errors.officeEmailError}
                        onInputChange={(e) => onInputChange(e)} 
                    />
                </div>
                <div className="col-lg-6 py-2">
                    <Input
                        label="Phone"
                        name="office_phone"
                        value={office_phone}
                        errorFieldName="Phone"
                        isInvalid={errors.officePhoneError}
                        isError={errors.officePhoneError}
                        isRequired={true}
                        onInputChange={(e) => onInputChange(e)} 
                    />    
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 py-2">
                    <Input
                        label="Legal Entity"
                        name="legal_entity_id"
                        value={loggedAdminDetails.legal_entity_id}
                        list={AttendanceType}
                        errorFieldName="Legal Entity"
                        isInvalid={errors.officeLEError}
                        isError={errors.officeLEError}
                        isRequired={true}
                        isDisabled={true}
                        onInputChange={(e) => onInputChange(e)} 
                    />    
                </div>
                <div className="col-lg-6 py-2">
                    <SelectView
                        label="Weekly Off Day"
                        name="office_off_day"
                        value={office_configuration.office_off_day}
                        list={WeeklyOffDays}
                        errorFieldName="Office Off Day"
                        isInvalid={errors.officeOffDayError}
                        isError={errors.officeOffDayError}
                        isRequired={true}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />  
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 py-2">
                    <SelectView
                        label="Attendance Type"
                        name="attendance_method"
                        value={office_configuration.attendance_method}
                        list={AttendanceType}
                        errorFieldName="Attendance Method"
                        isInvalid={errors.officeAttendanceTypeError}
                        isError={errors.officeAttendanceTypeError}
                        isRequired={true}
                        onInputChange={(e) => updateOfcConfigData(e)} 
                    />      
                </div>
                <div className="col-lg-6 py-2">
                    <Input
                        label="Address"
                        name="office_address"
                        value={office_address}
                        errorFieldName="Office Address"
                        isInvalid={errors.officeAddressError}
                        isError={errors.officeAddressError}
                        isRequired={true}
                        onInputChange={(e) => onInputChange(e)} 
                    />    
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 py-2">
                    <Input
                        label="Description"
                        name="office_description"
                        value={office_description}
                        isRequired={true}
                        isInvalid={errors.officeDescError}
                        isError={errors.officeDescError}
                        onInputChange={(e) => onInputChange(e)} 
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end pt-3 px-2">
                <button type="button" className="btn btn-outline-danger mt-3 mr-3 px-5" onClick={handleClose}>Cancel</button>
                <button type="submit" className="btn btn-danger ms-3 mt-3 px-5">Next</button>
            </div>
        </form>
	)
}

export default OfficeDetails;