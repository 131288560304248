import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getConfigurationByLE} from '../../actions/filters.actions.js';
import Dialog from '@mui/material/Dialog';
import {baseURL} from '../../actions/Constants';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Input from '../../components/Input';
import Loading from '../../components/loading/Loading';
import TimePickerView from '../../components/TimePicker/TimePickerView';
import {isTextValid} from '../../util/DateTimeConverter';

import emp_icon from '../../assets/images/emp.png';
import circle_logo from '../../assets/images/circle.png';

const EditShift = (props) => {
    const dispatch = useDispatch();
    const { isOpen = false , details = {}, onHandleCloseDialog} = props;
    const {
        details: payrollDetails
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const {
        details: filterDetails
    } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));
    
    const initErrorState = {
        nameError: false,
        inTimeError: false,
        outTimeError: false
    };
    
    const [errors, setErrors] = useState(initErrorState);
    const [payroll, setPayroll] = useState(details);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(isOpen);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [isError, setError] = useState(false);
    const [allPayrollData, setPayrollData] = useState([]);
    const [isFormSubmit, setFormSubmit] = useState(false);
    const [isValidForm, setValidForm] = useState(true);

    const isEdit = details && details.shift_id !== "";

    useEffect(() => {
        if (!isValidForm) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setValidForm(true);
        }
    }, [isValidForm]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setPayrollData(payrollDetails);
    }, [payrollDetails]);
  
    const handleClose = (event, reason) => {
       // if (reason && reason == "backdropClick") return;
        setOpen(false);
        onHandleCloseDialog();
    };

    const onInputChange = (e) => setPayroll({...payroll, [e.target.name] : e.target.value});

    const setUpdateTime = (key, value) => {
        setPayroll({...payroll, [key] : value});
    }

    const validate = (e) => {
        let isFormValid = true;
        let isinValidName = false; 
        let isinValidInTime = false;
        let isinValidOutTime = false;

        if (!isTextValid(shift_name)) {
            isFormValid = false;
            isinValidName = true;
        }
        if (!isTextValid(shift_in_time)) {
            isFormValid = false;
            isinValidInTime = true;
        }

        if (!isTextValid(shift_out_time)) {
            isFormValid = false;
            isinValidOutTime = true;
        }
        
        setErrors({...errors,
            nameError: isinValidName,
            inTimeError: isinValidInTime,
            outTimeError: isinValidOutTime
        });
        setValidForm(isFormValid);
        return isFormValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
     
        const isFormValid = validate();
        if (isFormValid) {
            if(isEdit) {
                updateConfigurationByLE();
            }  else {
              addConfigurationByLE();
            }
            setFormSubmit(true);
        }
    };

    const addConfigurationByLE = async () => {
        let config_Data = JSON.parse(allPayrollData[0].legal_entity_configuration);
        payroll.shift_id = "" + new Date().getTime();

        config_Data.shift_times[config_Data.shift_times.length] = payroll;
       // config_Data.shift_times[config_Data.shift_times.length] = payroll;

        const obj = config_Data;
        const URL=`${baseURL}/updateConfigurationByLE`;

        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((res) => {
          //Close Dialog
            
            setError(false);
            if(filterDetails && filterDetails.emp_legal_entity_id !== "") {
                dispatch(getConfigurationByLE(filterDetails.emp_legal_entity_id)).then(() => {
                    onHandleCloseDialog();
                    setLoading(false);
                });
            }
        })
        .catch((error) => {
            console.log('error updateConfigurationByLE', error);
            setLoading(false);
            setError(true);
        });
    };

    const updateConfigurationByLE = async () => {
        let config_Data = JSON.parse(allPayrollData[0].legal_entity_configuration);
    
        const index = config_Data.shift_times.findIndex(x => x.shift_id === payroll.shift_id);
        config_Data.shift_times[index] = payroll;

        const obj = config_Data;
        const URL=`${baseURL}/updateConfigurationByLE`;
        //const URL="";

        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((res) => {
          //Close Dialog
            setError(false);
            if(filterDetails && filterDetails.emp_legal_entity_id !== "") {
                dispatch(getConfigurationByLE(filterDetails.emp_legal_entity_id)).then(() => {
                    onHandleCloseDialog();
                    setLoading(false);
                });
            }
            
        })
        .catch((error) => {
            console.log('error updateConfigurationByLE', error);
            setLoading(false);
            setError(true);
        });
    };

    const {
        shift_id = "",
        shift_name = "",
        shift_in_time = "",
        shift_out_time = "",

    } = payroll || {};

    const title = isEdit ? 'Edit Shift' : 'Add Shift';

    return (
        <>
            {isLoading ? <Loading /> :
                (
                <Dialog 
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={isOpen}
                    onClose={handleClose}
                    aria-label=""
                    className="md"
                   // onBackdropClick="false"
                >
               
                    <div className="container p-lg-5 p-3">
                        <div className="d-flex align-items-center">
                            <img src={circle_logo} className="circle_logo" />
                            <div className="logo_black ps-3"><span className="logo_red">One</span> Attendance</div> 
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                           
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div> 
                                      <img src={emp_icon} className="pe-2" /> 
                                    </div>

                                    <div>
                                        <div className="dialog_h2_heading">{title}</div>
                                        <div className="dialogSubText mt-3">
                                            <div>Please edit the information you want to</div>
                                            <div>update the settings, in the form below</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                
                            </div>
                        </div>

                        <form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate>
                            <div className="row">
                                <div className="col-lg-12 py-2">
                                    <Input
                                        label="Shift Name"
                                        name="shift_name"
                                        value={shift_name}
                                        errorFieldName="Shift name"
                                        isInvalid={errors.nameError}
                                        isError={errors.nameError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 py-4">
                                    <p className="field pb-2">Shift In Time *</p>
                                    <TimePickerView 
                                        label="In Time"
                                        name="shift_in_time"
                                        value={shift_in_time} 
                                        isRequired={true}
                                        isInvalid={errors.inTimeError}
                                        isError={errors.inTimeError}
                                        setUpdateTime={(value) => setUpdateTime("shift_in_time", value)} 
                                    />  
                                </div>

                                <div className="col-lg-6 py-4">
                                    <p className="field pb-2">Shift Out Time *</p>
                                    <TimePickerView 
                                        label="Out Time"
                                        name="shift_out_time"
                                        value={shift_out_time} 
                                        isRequired={true}
                                        isInvalid={errors.outTimeError}
                                        isError={errors.outTimeError}
                                        setUpdateTime={(value) => setUpdateTime("shift_out_time", value)} 
                                    />   
                                </div>
                            </div>

                            <div className="d-flex justify-content-end pt-3">
                                
                                <button type="button" className="btn btn-outline-danger me-3 mt-3" onClick={handleClose}>Cancel</button>
                                <button type="submit" className="btn btn-danger mt-3 me-3">{isEdit ? 'Update' : 'ADD'}</button>
                            </div>
                        </form>
                    </div>
                </Dialog>)
            }
            {isError && <CustomizedSnackbars />}
        </>
    )
};

export default EditShift;