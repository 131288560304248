import FloatSelect from '../SelectElement';
import {useSelector} from 'react-redux';

const LESelectView = (props) => {
	const {name = "", title = "", setUpdateValue, label = "", value = "", isFullWidth = "30%"} = props;
    
    const {
        details
    } = useSelector(({ le: { details} }) => ({ details }));

	return (
		<FloatSelect
            label="Legal Entity"
            name={name}
            value={value}
            errorFieldName="Legal Entity"
            isInvalid={false}
            list={details}
            placeholder={title}
            keyVal="label"
            title="label"
            isFullWidth={isFullWidth}
            setUpdateValue={setUpdateValue}
        />
	)
}

export default LESelectView;