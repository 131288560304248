import SelectView from '../../components/Select/SelectView';
import {useSelector} from 'react-redux';

const POSSelectView = (props) => {
	const {name="", title="POS", onInputChange, label="", value=""} = props;

    const {
        details
    } = useSelector(({ office: { details} }) => ({ details}));

	return (
		<SelectView
            label="POS"
            name={name}
            value={value}
            errorFieldName="POS"
            isInvalid={false}
            list={details}
            placeholder={title}
            keyVal="office_id"
            title="office_name"
            labelVal="office_name"
            onInputChange = {(e) => onInputChange(e)}
            isStandard= {false}
        />
	)
}

export default POSSelectView;