import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ReactDOM from 'react-dom';
import { Modal, ButtonToolbar, Button } from 'rsuite';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Loading from '../../components/loading/Loading';
import {baseURL} from '../../actions/Constants';
import {getConfigurationByLE} from '../../actions/filters.actions.js';

const ConfirmPayroll = (props) => {
	const dispatch = useDispatch();
	const { isOpen = false , pageName="Shift", details = {}, onHandleCloseDialog} = props;

	const [open, setOpen] = useState(isOpen);
  	const handleOpen = () => setOpen(true);
  	const [allPayrollData, setPayrollData] = useState([]);
  	const [payroll, setPayroll] = useState(details);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const {
        details: payrollDetails
    } = useSelector(({ payroll: { details} }) => ({ details }));

     const {
        details: filterDetails
    } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

  	const handleClose = () => {
  		setOpen(false);
  		onHandleCloseDialog();
  	}

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	useEffect(() => {
        setPayrollData(payrollDetails);
    }, [payrollDetails]);
 
  	const updateConfigurationByLE = async () => {
        let config_Data = JSON.parse(allPayrollData[0].legal_entity_configuration);

        if(pageName === "Shift") {
        	const updatedPayroll = config_Data.shift_times.filter(x => x.shift_id !== payroll.shift_id);
        	config_Data.shift_times = updatedPayroll;
        } else if(pageName === "Payroll") {
    
        	const updatedPayroll = config_Data.emp_payroll_catagory.filter(x => x.name !== payroll.name);
        	config_Data.emp_payroll_catagory = updatedPayroll;
        } else if(pageName === "Job Role") {
          const updatedPayroll = config_Data.job_role_details.filter(x => x.job_role_id !== payroll.job_role_id);
          config_Data.job_role_details = updatedPayroll;
        } else if(pageName === "Emp Role") {
          const updatedPayroll = config_Data.emp_role_details.filter(x => x.emp_role_id !== payroll.emp_role_id);
          config_Data.emp_role_details = updatedPayroll;
        }
        
        const obj = config_Data;
        const URL=`${baseURL}/updateConfigurationByLE`;
      
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((res) => {
          //Close Dialog
            setLoading(false);
            setError(false);
            if(filterDetails && filterDetails.emp_legal_entity_id !== "") {
                dispatch(getConfigurationByLE(filterDetails.emp_legal_entity_id));
            }
            onHandleCloseDialog();
        })
        .catch((error) => {
            console.log('error updateConfigurationByLE', error);
            setLoading(false);
            setError(true);
        });
    };
	
	return(
		<>
			{isLoading ? <Loading /> :
			<Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
		        <Modal.Body>
		          <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
		           &nbsp; &nbsp; Do you want to delete the {pageName}?
		        </Modal.Body>
		        <Modal.Footer>
		          <Button onClick={updateConfigurationByLE} appearance="primary">
		            Ok
		          </Button>
		          <Button onClick={handleClose} appearance="subtle">
		            Cancel
		          </Button>
		        </Modal.Footer>
	      	</Modal>
	      	}
	      	{isError && <CustomizedSnackbars />}
      	</>
	)
}

export default ConfirmPayroll;
