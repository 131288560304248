import {getTodayDate, getMonthFirstDate} from './DateTimeConverter';

const InitialEmployee = {
    emp_id : "",
    emp_name : "",
    emp_email : "",
    emp_phone_no : "",
    emp_role : "",
    emp_designation : "",
    emp_gender : "",
    emp_manager_emp_id : "",
    legal_entity_id : "",
    emp_access_office_ids : {
        access_list : []
    },
    emp_base_office_id : "",
    emp_configuration : {
        auto_sign_in_out: false,
        gps_tracking_on: false,
        attendance_method: "None",
        wfh_enabled: false,
        overtime_enabled: true,
        payroll:"",
        jobrole: "",
        shift_in_time: null,
        shift_out_time: null
    },
    emp_permission_level : "",
    emp_profile_pic : ""
};

const InitialBeacon = {
	beacon_uuid: '',
    beacon_physical_id: '',
    showroom_id: '',
    showroom_name: '',
    beacon_role: 'Entry',
    showroom_base_lat: '',
    showroom_base_long: '',
    provisioning_date: '-',
    last_service: '-',
    threashold_value: '',
};

const InitialAttendance = {
	emp_id: '',
    date_of_attendance: '',
    leave_status: '',
    first_signin_time: '',
    last_signout_time: '',
    approver_emp_id: '',
    gps_trail: '-',
    signin_showroom_id: '-',
    signout_showroom_id: '-'
};


const InititalShiftData = {
    shift_id : "",
    shift_name : "",
    shift_in_time : null,
    shift_out_time : null
}

const InititalPayrollData = {
    payroll_id : "",
    name: "",
    description: "",
    emp_id_pattern: "",
    monthly_off_days: ""
}

const InititalJobRoleData = {
    job_role_id : "",
    job_role_name: ""
}

const InititalEmpRoleData = {
    emp_role_id : "",
    emp_role_name: ""
}

const InitialOffice = {
    office_id: "",
    office_phone: "",
    legal_entity_id: "",
    office_name: "",
    office_geofencing_conf: {
        lat : 0,
        lng: 0,
        radius: "300",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: ""
    },

    office_address: "",
    office_configuration: {
        office_off_day: "",
        attendance_method: ""
    },
    office_beacon_conf: [],
    office_wifi_conf: [
    {
        bssid:"d0:72:dc:ea:09:e0",
        ssid:"$t@yw!f!",
        capabilities:"[WPA2-EAP/SHA1-CCMP][RSN-EAP/SHA1-CCMP][ESS]",
        signal_strength:"-68",
        enabled: false,
        threshold:"Anywhere"
    }
    ],
    office_email: "",
    office_description: ""
}

const InitialFilters = {
    office_id: "all", /*POS*/
    emp_legal_entity_id: "",
    emp_id_pattern: "all", /*Payroll*/
    startDate: getMonthFirstDate(),
    endDate: new Date()
};

export { InitialEmployee, InitialBeacon , InitialOffice, InitialAttendance, InitialFilters, InititalPayrollData, InititalShiftData, InititalJobRoleData, InititalEmpRoleData };
