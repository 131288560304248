export const SAVE_FILTER_DATA = "SAVE_FILTER_DATA";
export const GET_FILTER_DATA   = "GET_FILTER_DATA";
export const REMOVE_FILTER_DATA   = "REMOVE_FILTER_DATA";

export const SAVE_LOGGEDIN_ADMIN = "SAVE_LOGGEDIN_ADMIN";
export const GET_ADMIN_START = "GET_ADMIN_START";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const REMOVE_LOGGEDIN_ADMIN = "REMOVE_LOGGEDIN_ADMIN";

export const GET_CONFIGURATION_START = "GET_CONFIGURATION_START";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";

export const GET_OFFICE_START = "GET_OFFICE_START";
export const GET_OFFICE_SUCCESS = "GET_OFFICE_SUCCESS";
export const GET_OFFICE_FAILURE = "GET_OFFICE_FAILURE";
export const SAVE_LE_DATA = "SAVE_LE_DATA";
export const GET_LE_DATA = "GET_LE_DATA";
export const REMOVE_LE_DATA = "REMOVE_LE_DATA";

export const baseURL     = "https://api.smmc.dev:8904/smmc-oneattendance";
export const PCC_OneAttendance_version = '1.0.3';

export const ALL_DATA = 

{
    "sheet_data": [
        {
            "label_header_1_1": "Emp ID",
            "label_header_1_2": "Branch",
            "le_logo_url": "www",
            "label_year": 2024,
            "attendance_data": [
                {
                    "emp_jobrole": "General",
                    "label_ot_hrs": "OT Hrs.",
                    "emp_branch": "Gariahat",
                    "shift_in_time": "11:00",
                    "emp_name": "SUSHYAMAL ADHIKARY",
                    "shift_out_time": "19:00",
                    "dates": [
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "10:45",
                            "date_of_month": "25",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "26",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "27",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "28",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "29",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "30",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "31",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "1",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "2",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "3",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "4",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "5",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "6",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "7",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "8",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "9",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "10",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "11",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "12",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "13",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "14",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "15",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "16",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "17",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "18",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "19",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "20",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "21",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "22",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "23",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "24",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        }
                    ],
                    "emp_designation": "SENIOR ASSISTANT MANAGER",
                    "label_intime": "In Time",
                    "total_ot_hrs": "00.00",
                    "label_outtime": "Out Time",
                    "emp_id": "E0016"
                }
            ],
            "le_name": "Jewellers",
            "oa_logo_url": "www",
            "sheet_name": "Attendance_Report",
            "label_month": "May",
            "label_report_month": "Monthly Attendance Report For: ",
            "label_dates": "31"
        },
        {
            "label_wd": "W/D",
            "attendance_summary_data": [
                {
                    "emp_jobrole": "General",
                    "emp_branch": "Gariahat",
                    "emp_name": "SUSHYAMAL ADHIKARY",
                    "num_absent_days": 25,
                    "num_working_days": 0,
                    "dates": [
                        {
                            "manual_approve_status": false,
                            "date_of_month": "25",
                            "status": "P"
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "26",
                            "status": "A"
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "27",
                            "status": "P"
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "28",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "29",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "30",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "31",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "1",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "2",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "3",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "4",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "5",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "6",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "7",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "8",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "9",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "10",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "11",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "12",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "13",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "14",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "15",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "16",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "17",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "18",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "19",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "20",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "21",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "22",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "23",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "24",
                            "status": " "
                        }
                    ],
                    "num_weekly_off": 6,
                    "emp_id": "E0016",
                    "total_working_days": 31
                }                
            ],
            "label_totaldays": "Total Days",
            "label_header_1_1": "Emp Name",
            "label_header_1_2": "Emp ID",
            "label_header_1_3": "Job Role",
            "oa_logo_url": "www",
            "label_report_month": "Monthly Attendance Summary For: ",
            "label_ab": "AB",
            "le_logo_url": "www",
            "label_year": 2024,
            "le_name": "Jewellers",
            "label_wo": "W/O",
            "sheet_name": "Attendance_Summary",
            "label_month": "May",
            "label_dates": "1...31"
        }
    ]
}

