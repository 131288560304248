import React, { useState, useEffect } from 'react';
import Map from '../../components/map/Map';


const MpaView = (props) => {
  const {lat = 0, lng = 0} = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const obj = {};
    obj.latitude = lat;
    obj.longitude = lng;
    
    const dataArray = [];
    dataArray.push(obj);
    
    setData(dataArray);
  }, [lat]);

  useEffect(() => {
    if(data && data.length > 0) {
      data[0].circle = {
        radius: 3000,
        options: {
          strokeColor: "#ff0000"
        }
      };
    }
  }, [data]);

  return (
    <Map
      center={{ lat: lat, lng: lng }}
      zoom={12}
      places={data}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD7NQGp0HQTEFv84tnIy2_gXGcDywV0-pk"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}

export default MpaView;

