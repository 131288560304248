import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from '../../components/loading/Loading';
import {InitialFilters} from '../../util/DefaultInitialValues';
import POSSelectView from '../../components/POSSelectView/POSSelectView';
import PayrollSelectView from '../../components/PayrollSelectView/PayrollSelectView';
import LESelectView from '../../components/LESelectView/LESelectView';
import {saveFilters} from '../../actions/filters.actions.js';

import { SelectPicker } from 'rsuite';
import {getConfigurationByLE} from '../../actions/filters.actions.js';
import {getAllOfficesByLegalEntity} from '../../actions/offices.actions.js';

import {
  Legalentity_super_admin,
  Legalentity_Admin,
  Legalentity_Apex,
  Legalentity_Goldlites,
  Legalentity_Jewellers,
  Legalentity_Sons
} from '../../util/utils.js';

import wave from '../../assets/wave.png';
import ofc_icon from '../../assets/images/ofc.png';
import pcc_logo from '../../assets/ic_logo_oneattendance5.png';

const ConfigureDevice = () => {
  const dispatch = useDispatch();
  const titleVal = "Configure device - pcccrm";
	const hrefVal = window.location.href;
	const navigate = useNavigate();
  const [filters, setFilters] = useState(InitialFilters);
  const saveFilterDataAction = saveFilters;
  const [isFormValid, setFormValid] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const {
    details,
    loading,
    error
  } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

	const handleSubmit = (e) => {
		e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      dispatch(saveFilterDataAction(filters));
      navigate("/");
    }
	}

  const validate = () => {
    let isFormValid = true;
    let isInvalidPOSIDError = false;
    let isInvalidLEError = false;

    if(emp_legal_entity_id === "") {
      isInvalidLEError = true;
      isFormValid = false;
    }

    setFormValid(isFormValid);
    return isFormValid;
  }

  const checkState = () => {
    /*if(emp_id_pattern === "" && office_id !== "") {
      return true;
    } else if (emp_id_pattern !== "" && office_id === "") {
      return true;
    } else {
      return false;
    }*/

    if(office_id === "") {
      return true;
    }

    return false;
  }

  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = "",
    date = ""
  } = filters;

  useEffect(() => {
    if(emp_legal_entity_id !== "") {
      dispatch(getAllOfficesByLegalEntity(emp_legal_entity_id));
      dispatch(getConfigurationByLE(emp_legal_entity_id));
      dispatch(getConfigurationByLE(emp_legal_entity_id));
    }
  }, [emp_legal_entity_id]);

  const handleLEChange = (value) => {
    setFilters({...filters, ["emp_legal_entity_id"]: value, office_id: "all", emp_id_pattern: "all"});
  }

  const updateSelectValue = (name, value) => {
    setFilters({...filters, [name]: value});
  }

  useEffect(() => {
    setFilters(details);
  }, [details]);

	return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div className="login">
        <div className="wave">
          <img src={wave} alt = "" />
        </div>
        
        <div className="pcc_logo_container">
          <img src={pcc_logo} alt = "" className="pcc_logo" />
          <div className="ps-3 powered_by_text"><span className="powered_by">powered by</span> <span className="fw-bold smmc_text">SMM:C</span></div>
        </div>

        <div className="footer-text">© SMM:C · Contact · Privacy & terms</div>
        <div className="login_container">
          <div className="login-content flex-column">
            <div className="d-flex align-items-center my-3">
                <span className="pe-2 logo_black inter_regular">Welcome to </span>
                <div className="logo_black"><span className="logo_red">One</span> Attendance</div> 
            </div>
            <div className="mt-5">
              <div className="d-flex align-items-center">
                <img src={ofc_icon} className="pe-3 scale" alt = "" /> 
                <div>
                  <div className="dialog_h2_heading">Select Organization</div>
                </div>

              </div>
            </div>
           
            <div className="mt-5 mb-3">
              <div className="mb-2">Legal Entity</div>
              <LESelectView name="emp_legal_entity_id" isFullWidth = "75%" value={emp_legal_entity_id} setUpdateValue={(name, value) => handleLEChange(value)} />
            </div>

            <div className="my-3">
              <button className="btn btn-danger px-lg-5 my-3" type="submit" disabled={checkState()} onClick={handleSubmit}>Next</button>
            </div>
          </div>
         
        </div>
      </div>

      {isLoading && <Loading />}
    </>
	)
};

export default ConfigureDevice;