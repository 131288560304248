import React from 'react';
import MUIDataTable from "mui-datatables";
import './table.scss';


const Table = ({title="", headers = [], rowData = [], clsName="", options}) => {
  return (
    <MUIDataTable
      className = {`gfg ${clsName}`}
      title={title}
      data={rowData}
      columns={headers}
      options={options}
    />
  );
}

export default Table;
