import * as React from 'react';
import TextField from '@mui/material/TextField';

export default function FloatTextField(props) {
  const { classVal = "", id= "", isError = false, label = "", type="text", name="", value="", isDisabled=false, isShowLabel, isRequired=false, onInputChange} = props;

  const onHandleChange = (e) => {
    onInputChange(e);
  }

  return (

      <TextField 
        className={classVal}
        disabled={isDisabled}
        value={value}
        error={isError}
        name={name}
        id={id}
        label={label}
        required={isRequired}
        variant="standard"
        onChange={(e) => onHandleChange(e)} 
      />  
  );
}
