import {useState, useEffect} from 'react';
import Input from '../../components/Input';
import SelectView from '../../components/Select/SelectView';
import ic_wifi2 from '../../assets/images/ic_wifi2.png';

import { 
    AttendanceType,
    isPhoneNumberValid
} from '../../util/utils';

const OfficeWifiDetails = (props) => {
    const {office = {}, setOffice, isEdit=false, handleClose, setStepper} = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        setStepper(3);
    } 

    const onInputChange = (e) => {
        setOffice({...office, [e.target.name] : e.target.value});
    }

    const updateOfcConfigData = (e) => {
        setOffice({...office, office_configuration: {...office_configuration, [e.target.name]: e.target.value}});
    }

    const {
        office_id = "",
        office_name = "",
        office_phone= "",
        legal_entity_id = "",
        office_address = "",
        office_wifi_conf = [],
        office_configuration = {
            office_off_day: "",
            attendance_method: ""
        },
        office_email = "",
        office_description = ""
    } = office || {};

    return (
        <form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate className="p-lg-5 p-md-5 p-2">
            <h6>Configure Wifi</h6>

            <p className="pt-2">Please configure your premise Wi-Fi</p>
            <p>You may skip this step and configure it at a later time</p>

            {/*<p className="py-3 fw-bold">Search Wi-Fi</p>
            <button className="btn btn-outline-danger">START WI-FI SCAN</button>*/}

            <p className="pt-3 fw-bold">Wi-Fi Range Thresold</p>
            <p>Distance from the WiFi which allows user to sign-in-out</p>

            {office_wifi_conf.map((item, index) => (
                <>
                    <div className="d-flex align-items-center justify-content-between my-3 rounded bg-light px-4 py-3 ">
                            <div className="d-flex align-items-center">
                                <div className="me-4">
                                    <img src={ic_wifi2} style={{width: '30px', padding: '4px'}} />
                                </div>
                                <div>
                                    <div className="fw-bold">{item?.ssid || "-"}</div>
                                    <div>{item?.bssid || "-"}</div>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" />
                            </div>
                    </div>
                </>
            ))}

            <div className="d-flex justify-content-end pt-3 px-2">
                <button type="button" className="btn btn-outline-danger mt-3 mr-3 px-5" onClick={() => setStepper(1)}>Back</button>
                <button type="submit" className="btn btn-danger ms-3 mt-3 px-5">Next</button>
            </div>
        </form>
    )
}

export default OfficeWifiDetails;