import {
  GET_CONFIGURATION_START,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_FAILURE,
  GET_OFFICE_START,
  GET_OFFICE_SUCCESS,
  GET_OFFICE_FAILURE,
  SAVE_FILTER_DATA,
  REMOVE_FILTER_DATA,
  GET_FILTER_DATA,
  SAVE_LE_DATA,
  baseURL
} from './Constants';

export const saveFilters = (filter) => (dispatch) => {
  dispatch({
    type: SAVE_FILTER_DATA,
    payload: filter,
    error: null
  });
};

export const removeFilters = () => (dispatch) => {
  dispatch({
    type: REMOVE_FILTER_DATA,
    payload: {},
    error: null
  });
};

export const getConfigurationByLE = le => async (dispatch) => { //Payroll
  dispatch({
    type: GET_CONFIGURATION_START,
    error: null
  });

  let obj = {};
  obj.legal_entity_id = le;

  //console.log(obj)
  
  const URL=`${baseURL}/getConfigurationByLE`;
 
  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    const le_config = {};
    dispatch({
      type: GET_CONFIGURATION_SUCCESS,
      payload: res,
      error: null
    });
  })
  .catch((error) => {
    console.log('error getConfigurationByLE', error);
    dispatch({
      type: GET_CONFIGURATION_FAILURE,
      error: error,
    });
  });
}

export const saveLegalEntity = (lEs) => (dispatch) => {
  dispatch({
    type: SAVE_LE_DATA,
    payload: lEs,
    error: null
  });
};





