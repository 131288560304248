import {useSelector} from 'react-redux';
import SelectView from '../../components/Select/SelectView';

const PayrollSelectView = (props) => {
	const {name = "", title = "", label = "", value = "", isRequired=false, isError = false, isInvalid = false, onInputChange,  isStandard=false} = props;

    const {
        details
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const payrollList = Array.isArray(details) ? JSON.parse(details[0].legal_entity_configuration).emp_payroll_catagory : [];

	return (
        <SelectView 
           label="Payroll"
            name={name}
            value={value}
            errorFieldName="Payroll"
            isRequired={isRequired} 
            isError={isError}
            isInvalid={isInvalid}
            list={payrollList}
            placeholder={title}
            keyVal="emp_id_pattern"
            title="name"
            labelVal="name"
            onInputChange = {(e) => onInputChange(e)}
            isStandard= {isStandard} 
        />
	)
}

export default PayrollSelectView;