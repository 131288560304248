import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import OfficeDetails from './OfficeDetails';
import OfficeWifiDetails from './OfficeWifiDetails';
import OfficePreviewDetails from './OfficePreviewDetails';
import OfficeLocationDetails from './OfficeLocationDetails';

import {
    isTextValid, 
    isEmailValid, 
    isPhoneNumberValid
} from '../../util/DateTimeConverter';

import {InitialOffice} from '../../util/DefaultInitialValues';
import ic_office_g from '../../assets/images/ic_office_g.png';
import circle_logo from '../../assets/images/circle.png';
import {baseURL} from '../../actions/Constants';
import './office.css';

const EditOffices = (props) => {
    const { isOpen = false , le ="SMMC", details = {InitialOffice}, onHandleCloseDialog, onUpdateSuccess} = props;
    
    const isEdit = details && details.office_id !== "";
    const title = isEdit ? 'Edit' : 'Add';

    const {
        details : payrollDetails
    } = useSelector(({ payroll: { details} }) => ({ details }));

    
    const [office, setOffice] = useState(details);
    const [open, setOpen] = useState(isOpen);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [stepper, setStepper] = useState(1);

    useEffect(() => {
        document.title = `${isEdit ? 'Edit' : 'Add'} Employee - PC Chandra`;
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
  

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;

        setOpen(false);
        onHandleCloseDialog();
    };

    const onInputChange = (e) => setOffice({...office, [e.target.name] : e.target.value});

    const updateGeoConfigData = (e) => {
        setOffice({...office, office_geofencing_conf: {...office_geofencing_conf, [e.target.name]: e.target.value}});
    }

    const updateOfcConfigData = (e) => {
        setOffice({...office, office_configuration: {...office_configuration, [e.target.name]: e.target.value}});
    }

    const {
        office_id = "",
        office_phone= "",
        legal_entity_id = "",
        office_name = "",
        office_geofencing_conf = {
          lat : "",
          lng: "",
          radius: "",
          address: "",
          city: "",
          state: "",
          country: "",
          postalCode: ""
        },

        office_address = "",
        office_configuration = {
          office_off_day: "",
          attendance_method: ""
        },
        office_beacon_conf = [],
        office_wifi_conf = [
            {
              bssid:"",
              ssid:"",
              capabilities:"",
              signal_strength:"",
              enabled: false,
              threshold:""
            }
        ],
        office_email = "",
        office_description = ""
    } = office || {};
  
    return (    
        <Dialog 
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isOpen}
            onClose={handleClose}
            aria-label=""
            className="md"
        >
            <div className="container-fluid p-5">
                <div className="d-flex align-items-center px-lg-5 px-md-5 px-2">
                    <img src={circle_logo} className="circle_logo" />
                    <div className="logo_black ps-3"><span className="logo_red">One</span> Attendance</div> 
                </div>

                <div className="d-flex align-items-center justify-content-between align-items-center px-lg-5 px-md-5 px-2">
                    <div className="d-flex justify-content-between align-items-center my-3">
                        <div className="d-flex align-items-center">
                            <img src={ic_office_g} className="pe-3" style={{width: '100px'}} /> 
                            
                            <div>
                                <div className="dialog_h2_heading">{title} Configure Office</div>
                                <div className="mt-3">
                                    <div className="dialogSubText">Please edit the information you want to</div>
                                    <div className="dialogSubText">update for this user,in the form below</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div>
                        <button className="ax-button" onClick={handleClose}>
                            <i class='bx bx-x-circle fs-4 opacity-75'></i>
                        </button>
                    </div>
                </div>

                {stepper === 1 && <OfficeDetails office = {office} setStepper = {setStepper} setOffice = {setOffice} isEdit = {isEdit} handleClose={handleClose} />}
                {stepper === 2 && <OfficeWifiDetails office = {office} setStepper = {setStepper} setOffice = {setOffice} isEdit = {isEdit} handleClose={handleClose} />}
                {stepper === 3 && <OfficeLocationDetails office = {office} setStepper = {setStepper} setOffice = {setOffice} isEdit = {isEdit} handleClose={handleClose} />}
                {stepper === 4 && <OfficePreviewDetails office = {office} setStepper = {setStepper} setOffice = {setOffice} isEdit = {isEdit} handleClose={handleClose} />}

            </div>
        </Dialog>
    )
};

export default EditOffices;