import Table from '../../components/table/Table';

const SummaryDetails = ({data = {}, MONTH_DAYS = 31, daysArray = []}) => {

  const getDataByKey = (reportData, day = "", key) => {
  	const filterItem = reportData && reportData.sheet_name !=="" && reportData.dates.filter((item, inx) => parseInt(item.date_of_month) === parseInt(day));
  	return filterItem.length === 0 ? "-" : filterItem[0][key];
  }

	return (
	<div className="my-5">
    <div className="container-fluid">
			  
		<div className="row mt-2">
		<div className="table-container">
        {data && data.attendance_summary_data.length > 0 && <table id="dtBasicExample" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
          <thead style={{ background: "yellow" }}>
            <tr>
            	<th key={0} scope="col">ID</th>
            	<th key={1} scope="col">Name</th>
              <th key={2} scope="col">Job Role</th>
            	{daysArray.map((day, index) => {
            		return <th key={index} scope="col">{day}</th>
            	})}
              <th key={1} scope="col">W/D</th>
              <th key={1} scope="col">W/O</th>
              <th key={1} scope="col">AB</th>
              <th key={1} scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {data.attendance_summary_data.map((reportData, index) => (
              <>
              <tr>
                <td className="text-center">{reportData.emp_id}</td>
                <td className="text-center">{reportData.emp_name}</td>
                <td className="text-center">{reportData.emp_jobrole}</td>
                {daysArray.map((day, index) => {
                  return <td className="text-center">{getDataByKey(reportData, day, "status")}</td>
                })}
                <td className="text-center">{reportData.num_working_days}</td>
                <td className="text-center">{reportData.num_weekly_off}</td>
                <td className="text-center">{reportData.num_absent_days}</td>
                <td className="text-center">{reportData.total_working_days}</td>
              </tr>
              
              </>
            ))}
          </tbody>
        </table>}
        {data && data.attendance_summary_data.length === 0 && <div>No attendance summary details found</div>}
      </div>
			</div>
        </div>
    </div>
	)
}

export default SummaryDetails;