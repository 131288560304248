import React, {useEffect, useState} from 'react';
import { SelectPicker } from 'rsuite';
import './select.scss';

const FloatSelect = (props) => {
  const {list = [], isFullWidth="100%", setUpdateValue, name = "", keyVal="", label="", title="", value = ""} = props;
  const [dataList, setDataList] = useState([]);
  const [val, setVal] = useState(value);

  useEffect(() => {
    if(dataList.length === 0) {
      const array = [];

      if(label !== "Legal Entity") {
        const obj = {};
        obj.value = "";
        obj.label = "All";
        array.push(obj);
      }
      
      const dataList = Array.isArray(list) && list.map((item, index) => {
        const obj = {};
        obj.value = item[keyVal];
        obj.label = item[title];
        array.push(obj);
      });

      setDataList(array);
    }
  }, [list]);

  const handleChange = (value) => {
    const selectedVal = value;
    setUpdateValue(name, selectedVal);
  }

  useEffect(() => {
    setVal(value);
  }, [value]);

  return(
      <SelectPicker
        name={name}
        defaultValue={val}
        value={val}
        data={dataList}
        placeholder={label}
        style={{ width: isFullWidth, minWidth: '210px'}}
        searchable={false}
        cleanable={false}
        onChange={handleChange}
      />  
   
  )
}

export default FloatSelect;