import {
  GET_CONFIGURATION_START,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_FAILURE,
  baseURL
} from '../../actions/Constants';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function payrollReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CONFIGURATION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_CONFIGURATION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}