import React, {useEffect, useState} from 'react';
import FloatSelect from '../SelectElement';
import {useSelector} from 'react-redux';
import SelectView from '../../components/Select/SelectView';

const JobRoleSelectView = (props) => {
	const {emp_id_pattern = "", name = "", title = "", label = "", value = "", isRequired=false, isError = false, isInvalid = false, onInputChange} = props;

    const {
        details
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const JobRoleFullList = Array.isArray(details) ? JSON.parse(details[0].legal_entity_configuration).job_role_details : [];
    const JobRoleList = emp_id_pattern === "" ? JobRoleFullList : JobRoleFullList.filter((item, inx) => item.emp_id_pattern === emp_id_pattern);

	return (
        <SelectView 
        	name={name} 
        	isRequired={isRequired} 
            isError={isError}
            isInvalid={isInvalid}
        	value={value} 
            label = {label} 
        	keyVal = "job_role_name" 
        	labelVal = "job_role_name" 
        	list = {JobRoleList} 
        	onInputChange = {(e) => onInputChange(e)} 
        />
	)
}

export default JobRoleSelectView;