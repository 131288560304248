import React, {useState, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PCC_OneAttendance_version} from './actions/Constants';

const Protected = ({children}) => {

	const location = useLocation();

  	const {
    	details,
    	loading,
    	error
  	} = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  	const [currentLocation, setLocation] = useState("");

  	useEffect(() => {
        let locationVal = location?.pathname;
       	setLocation(locationVal);
   }, [location]);

   const validatePath = () => {
   		if(currentLocation === "" || currentLocation === "/" || currentLocation === "/configure-device") {
   			if(details && details?.emp_role && details?.emp_role.toLowerCase().includes('admin')) {
   				return true;
   			}
   			return false;
   		} else if(currentLocation === "/report") {
   			if(details && details?.emp_role && details?.emp_role.toLowerCase().includes('admin')) {
   				return true;
   			}
   			return false;
   		}  else if(currentLocation === "/roster") {
        if(details && details?.emp_role && details?.emp_role.toLowerCase().includes('admin')) {
          return true;
        }
        return false;
      } else {
   			if(details && details?.emp_role && details?.emp_role === "Administrator") {
   				return true;
   			}
   			return false;
   		}
   	}

  	//const isUserPresent = (details && details.role === "" && details.emp_phone_no !== "") ? true : false;

  	const redirectPath = () => {

      if(!isSameVersion) return "/login";
      
  		if(details && details?.emp_role && details?.emp_role.toLowerCase().includes('admin')) {
  			return "/";
  		} else {
  			return "/login";
  		}
  	}

    const app_version = localStorage.getItem("PCC_OneAttendance_version");
    const isSameVersion = (app_version && app_version === PCC_OneAttendance_version);

    const isUserPresent = (validatePath() && isSameVersion) ? true : false;
  
  	return isUserPresent ? children : <Navigate to={redirectPath()} replace />;
};

export default Protected;