import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

import './index.css';

function saveToSessionStorage(state) {
	try {
		const {loggedAdmin:newLoggedAdmin, currentFilters:newFilters} = state;
		const obj = {};
		obj.loggedAdmin = newLoggedAdmin;
		obj.currentFilters = newFilters;
		const serializedState = JSON.stringify(state); //state

		localStorage.setItem('PCC_OneAttendance_admin', serializedState);
	}
	catch {
		console.log('error');
	}
}

function loadFromLocalStoarge() {
	try {
		//localStorage.removeItem('state');
		const serializedState = localStorage.getItem('PCC_OneAttendance_admin');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);	
	} catch(e) {
		return undefined;
	}
}

const persistedState = loadFromLocalStoarge();
const storeEnhancer = compose(
    applyMiddleware(thunk),
    /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
);

export const store = createStore(
	rootReducer,
	persistedState,
	storeEnhancer,
);

// If you want to start measuring performance in your app, pass a function
store.subscribe(()=> saveToSessionStorage(store.getState()));

ReactDOM.render(
    <React.StrictMode>
       <Provider store={store}>
    		<App />
    	</Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
