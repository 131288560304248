import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper'
import Table from "../components/table/Table";
import { colors, data } from '../constants';

const Dashboard = () => {

    const navigate = useNavigate();
    
    useEffect(() => {
        navigate("/report")
    }, []);

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12 col-md-12">
                       <h3>Under Development</h3>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default Dashboard
