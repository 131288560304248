import Table from '../../components/table/Table';

const ReportDetails = ({data = {}, MONTH_DAYS = 31, daysArray = []}) => {
    const getDataByKey = (reportData, date, key) => {
    	const filterItem = reportData && reportData.sheet_name !=="" && reportData.dates.filter((item, inx) => parseInt(item.date_of_month) === parseInt(date));
    	return filterItem.length === 0 ? "-" : filterItem[0];
    }

    const createheaders = () => {
    	const updatedData = daysArray.map((item, i) => {
    		<th scope="col">{i}</th>
        	
    	})

    	return updatedData;
    }

    const createColumns = () => {
    	const obj = {};

    	const array = [];

    	const updatedData = daysArray.map((item, i) => {

    		const obj = {};

        	obj.name = "date_of_month";
    	  	obj.label = i + 1;

    	  	obj.options = {
		        filter: false,
		        sort: false,
		        empty: true,
		        customBodyRender: (value, tableMeta, updateValue) => {
	            return (
	              <span>-</span>
	            );
		        }
          }

    		array.push(obj);
    	});

    	return array;
    }

    const columns = [
        {
          name: "emp_id",
          label: 'ID',
          options: {
           filter: true,
           sort: true,
           viewColumns: false,
          }
        },
        {
          name: "emp_name",
          label: 'Name',
          options: {
           filter: true,
           sort: true,
          }
        },
        ...createColumns()
    ];


    const options = {
      selectableRows: false,
      filter: false,
      download: true,
      print: true,
      rowsPerPage: 100,
      sortOrder: {
        name: 'emp_device_uuid',
        direction: 'asc'
      }
    };

	return (
	<div className="my-5">
    <div className="container-fluid">
			  
		<div className="row mt-2">
		  <div className="table-container">
        {data && data.attendance_data.length > 0 && <table id="dtBasicExample" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
          <thead>
            <tr>
            	<th key={1} scope="col">ID</th>
            	<th key={2} scope="col">Branch</th>
            	{daysArray.map((item, index) => {
            		return <th key={index+3} scope="col">{item}</th>
            	})}
              <th key={33} scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {data.attendance_data.map((reportData, index) => (
              <>
              <tr>
                <td className="text-center fw-bold">{reportData.emp_id}</td>
                <td colSpan="6" className="text-center1 fw-bold">{reportData.emp_branch}</td>
                <td colSpan="6" className="text-center1 fw-bold">{reportData.emp_name}</td>

                <td colSpan="30" className="text-center1 fw-bold">{reportData.emp_designation} - {reportData.emp_jobrole}</td>
              </tr>
              <tr>
                <td className="text-center">In time</td>
                <td>{''}</td>
                {daysArray.map((day, index) => {
                  const obj = getDataByKey(reportData, day, 'in_time');
                  let val = "-";
                  let color = '';

                  if(obj && obj !== "-") {
                    val = obj['in_time'];
                    color = obj?.manual_approve_status ? 'amber': '';
                  }
                  return <td className={`text-center ${color}`}>{val}</td>
                })}
  
              </tr>

              <tr>
                <td className="text-center">
                  Out time
                </td>
                <td>
                  {" "}
                </td>
                
                {daysArray.map((day, index) => {
                  const obj = getDataByKey(reportData, day, 'out_time');
                  let val = "-";
                  let color = '';
                  
                  if(obj && obj !== "-") {
                    val = obj['out_time'];
                    color = obj?.manual_approve_status ? 'amber': '';
                  }
                  return <td className={`text-center ${color}`}>{val}</td>
                })}
              </tr>

               <tr>
                <td className="text-center">
                  OT Hrs.
                </td>
                <td>
                  {" "}
                </td>
                
                {daysArray.map((day, index) => {
                  const obj = getDataByKey(reportData, day, 'ot_hrs');
                
                  let val = "-";
                  
                  if(obj && obj !== "-") {
                    val = obj['ot_hrs'];
                   
                  }
                  return <td className="text-center">{val}</td>
                })}
                <td className="text-center">{reportData.total_ot_hrs}</td>
              </tr>
              </>
            ))}
          </tbody>
        </table>}
        {data && data.attendance_data.length === 0 && <div>No attendance details found</div>}
      </div>
			</div>
    </div>
  </div>
	)
}

export default ReportDetails;