import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import SelectView from '../../components/Select/SelectView';

const RoleSelectView = (props) => {
	const {name = "", title = "", label = "", value = "", isRequired=false, isError = false, isInvalid = false, onInputChange} = props;

    const {
        details
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const RoleList = Array.isArray(details) ? JSON.parse(details[0].legal_entity_configuration).emp_role_details : [];

	return (
        <SelectView 
        	name={name}
            label = {label} 
        	keyVal = "emp_role_name" 
            labelVal = "emp_role_name" 
        	list = {RoleList}
            value={value} 
            isRequired={isRequired} 
            isError={isError}
            isInvalid={isInvalid}
        	onInputChange = {(e) => onInputChange(e)} 
        />
	)
}

export default RoleSelectView;