import { Toggle } from 'rsuite';
import './switch.scss';

const SwitchView = (props) => {
	const {name = "", label = "", value = "", updateSwitchValue} = props;

	return (
		<div className="d-flex justify-content-between">
		<div>{label}</div>
		<Toggle name={name} checked={value} onChange={(val) => updateSwitchValue(name, val)} />
		</div>
	)
}

export default SwitchView;